import axios from 'axios';
import React, { useState } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { graphql } from 'gatsby';
import { chakra, Box, Container, HStack, VStack, Button, Text, Highlight, CircularProgress } from '@chakra-ui/react';

import { Icon } from 'components/view';
import { MetaData } from 'components/meta';
import { CurlyArrow } from 'components/visual';
import { VectorBackground } from 'components/view';
import { DefaultLayout, Card, MessageLayout, PageSwitchLayout } from 'components/layout';

import { getServerlessFuncEndpoint } from 'utils/helper/site';

const handleConfirm = async (contactData, onSuccess, onFail) => {
    try {
        const mailSendEndPoint = getServerlessFuncEndpoint('mailListConfirm');
        await axios.post(mailSendEndPoint, JSON.stringify(contactData));

        onSuccess();
    }
    catch (err) {
        onFail(err.message);
    }
}

const ButtonWithProgress = ({ onClick }) => {
    const { t } = useTranslation();
    const [isProgressVisible, setProgressVisible] = useState(false);

    const handleButtonClick = () => {
        setProgressVisible(true);
        onClick();
    };

    const PageButton = () => <Button
        onClick={handleButtonClick}
        leftIcon={<Icon name="bx-like" color="white" />}>
        {t('confirm_button_title')}
    </Button>;

    const PageProgress = () => <Box align="center">
        <CircularProgress
        isIndeterminate
        capIsRound
        size="2.5em"
        thickness=".35em"
        color="primary.300" />
        </Box>;

    return <PageSwitchLayout
        pageInitial={<PageButton />}
        pageFinal={<PageProgress />}
        showFinalPage={isProgressVisible} />;
}

const ConfirmPage = ({ location }) => {
    const { t } = useTranslation();
    const [isConfirmed, setConfirmed] = useState(false);

    const params = new URLSearchParams(location.search);
    const contactData = {
        email: decodeURI(params.get('email')) || 'unknown email address',
        consentHash: params.get('consentHash') || 'unknown-1234567890',
    };

    const handleConfirmClick = () => {
        handleConfirm(
            contactData,
            () => setConfirmed(true),
            () => setConfirmed(true)
        );
    };

    const CardWrapper = ({ children }) => <Container
        maxW="container.md"
        p={{ base: 3, md: 16 }}>
        {children}
    </Container>;

    const bodyDescription = t('body_description', { email: contactData.email });
    const ActionComponent = () => <Card>
        <VStack alignItems="stretch" w="full" gap={2}>
            <VStack alignItems="start">
                <HStack>
                    <chakra.h1>{t('body_header')}</chakra.h1>
                    <CurlyArrow color="primary.300" />
                </HStack>
                <Text>
                    <Highlight
                        query={[contactData.email]}
                        styles={{ px: '2', py: '1', rounded: 'lg', color: 'black', bg: 'secondary.300' }}>
                        {bodyDescription}
                    </Highlight>
                </Text>
            </VStack>
            <ButtonWithProgress onClick={handleConfirmClick} />
        </VStack>
    </Card>;

    const PageInitial = () => <CardWrapper>
        <ActionComponent />
    </CardWrapper>;

    const PageFinal = () => <CardWrapper>
        <MessageLayout
            icon="bx-message-alt-check"
            title={t('confirm_title')}
            message={t('confirm_message')} />
    </CardWrapper>;

    return (<>
        <MetaData location={location} />
        <DefaultLayout>
            <VectorBackground
                pattern="plaid"
                scale={10}
                colors={[
                    'secondary.300',
                    'rgb(196,212,175)',
                    'rgb(176,229,216)',
                    'cyan.50',
                ]}
                w="full"
                pb={12}
                pt={{ base: '4rem', md: '7rem' }}>
                <PageSwitchLayout
                    pageInitial={<PageInitial />}
                    pageFinal={<PageFinal />}
                    showFinalPage={isConfirmed} />
            </VectorBackground>
        </DefaultLayout>
    </>)
};

export default ConfirmPage;

export const PageConfirmPageQuery = graphql`
query PageConfirmPageQuery($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}, ns: {in: ["common", "confirm"]}}) {
        ...TranslationFragment
    }
}`;
